
.homeBody {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    position: relative;
}



@media screen and (max-width: 750px) {

    /* Tela Miúda */
    /*.homeBody {
        display: flex;
        flex-direction: column;
        background-color: #f7f7f7;
    }*/

    .homeMain {
        background-color: #f7f7f7;
    }


    .homeImg {
        width: 100px;
    }
}

@media screen and (min-width: 750px) {

    /* Tela normal */
   /* .homeBody {
        display: flex;
        padding: 10px;
        background-color: #f7f7f7;

    }*/

    .homeMain {
        margin-left: 45vh;
        background-color: #f7f7f7;

    }

    .div1 {
        color: #1a2f67;
    }
    .divInputsHomeBreak{
        flex-basis: 100%;
        height: 0;
    }
    .divInputsHome {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        width: 44%;
        margin-left: 6vh;
    }

    .divInputsHome1 {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        width: 45%;
        margin-left: 5.2vh;
    }

    .formHome {
        border-top: 0px #1a2f67 solid;
        border-radius: 10px 10px 10px 10px;
        margin-top: 1%;
        padding-bottom: 1%;
        background-color: #fefeff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    .inputHome {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 500;
        padding-left: 3%;
        padding-right: 3%;
        width: 90%;
        height: 3rem;
        border: #c6c6c6 2px solid;
        border-radius: 8px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    }

    .selectHome {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 500;
        padding-left: 3%;
        padding-right: 3%;
        height: 40px;
        border: #cfcfcf 2px solid;
        border-radius: 4px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        width: 71%;
    }

    .labelHome {
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .formH2 {
        padding-left: 3%;
        width: 100%;
        color: #1a2f67;
        text-align: start;
    }

    .ahead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .botaoHome {
        display: flex;
        text-align: center;
        padding: 1.5vh;
        width: 97%;
        color: #fefeff;
        border-radius: 8px;
        font-weight: 600;
        justify-content: center;
    }

    .iconCalc {
        padding-left: 10px;
    }

    .botaoLogout {
        justify-items: flex-end;
        /* Remova as estilizações padrão */
        border: none;
        background: none;
        outline: none;
        box-shadow: none;

        /* Defina os estilos personalizados */
        font-weight: bold;
        color: #9ad413;
        cursor: pointer;
    }
    .inputHome2 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 500;
        padding-left: 3%;
        padding-right: 3%;
        height: 3rem;
        border: #c6c6c6 2px solid;
        border-radius: 8px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    }
    .divInputsHome select {
        outline: none;
     }
}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 109dpi) {
  /*  .homeBody {
        font-size: 20px;
    }*/

    .inputHome {
        font-size: 18px;
    }
    .inputHome2 {
        font-size: 18px;
    }
}